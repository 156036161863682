<script setup>
import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import 'mapbox-gl/dist/mapbox-gl.css'
import mapboxgl from 'mapbox-gl'

import { DialogCreate, MapProjectLayer, MapDraw } from '@/components/map'

mapboxgl.accessToken = 'pk.eyJ1IjoiZWFydGhwYmMiLCJhIjoiY2x1dGRiMGU4MDdrdjJsb2ducG5pbG41ayJ9.XiX9hVsMSiOXSPf78V3dag'

const route = useRoute()
const projectId = computed(() => parseInt(route.params.project_id))

const store = useStore()
const isLoggedIn = computed(() => store.getters.getIsLoggedIn)
const layersToken = computed(() => store.getters['geo/getTilesToken'])
const layers = computed(() => store.getters['geo/getLayers'])
const mapReady = computed(() => store.getters['geo/getMapReady'])
const projects = computed(() => store.getters['project/getProjects'])
const flatedProjects = computed(() => store.getters['project/getFlatedProjects'])

const projectsToRender = computed(() => {
  if (!projectId.value) {
    return projects.value
  } else {
    const currentProject = flatedProjects.value.find((project) => project.project_id === projectId.value)

    if (!currentProject) return null

    if (currentProject?.children?.length > 0) {
      return [...currentProject.children, currentProject]
    } else {
      return [currentProject]
    }
  }
})

const params = computed(() => store.getters['search/getParams'])

const mapParams = computed(() => {
  const zoom = params.value.find((param) => param.key === 'zoom')
  const centerLat = params.value.find((param) => param.key === 'center_lat')
  const centerLng = params.value.find((param) => param.key === 'center_lng')

  return zoom && centerLat && centerLng ? {
    center: [centerLng.value, centerLat.value],
    zoom: zoom.value
  } : null
})

const localMapOptions = JSON.parse(localStorage.getItem('mapOptions'))

const map = ref(null)

const defaultOptions = {
  center: [-91.874, 42.76],
  zoom: 4
}

const createMap = async () => {
  map.value = new mapboxgl.Map({
    container: 'mapDiv',
    minZoom: 1,
    maxZoom: 17,
    // merge options from local storage with default
    ...mapParams.value ? mapParams.value : localMapOptions ? localMapOptions : defaultOptions
  })

  map.value.addControl(new mapboxgl.NavigationControl(), 'bottom-right')
  map.value.addControl(
    new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },
      trackUserLocation: true,
      showUserHeading: true
    }), 'bottom-right'
  )

  store.commit('geo/SET_MAP', map.value)
}

const addRasterLayers = async () => {
  const protocol = location.protocol === 'https:' ? 'https' : 'http'
  const host = `${protocol}://${window.location.host}`
  const baseSources = {
    'osm-tiles-source': {
      'type': 'raster',
      'tiles': ['https://tile.openstreetmap.org/{z}/{x}/{y}.png'],
      'tileSize': 256,
      'attribution':
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    },
    'osmtopo-tiles-source': {
      'type': 'raster',
      'tiles': ['https://tile.opentopomap.org/{z}/{x}/{y}.png'],
      'tileSize': 256,
      'attribution':
        'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, ' +
        '<a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">' +
        'OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
    },
    'mapbox-default-source': {
      'type': 'vector',
      'url': 'mapbox://mapbox.mapbox-streets-v8'
    }
  }

  const baseLayers = [
    {
      'id': 'osm-tiles-layer',
      'type': 'raster',
      'source': 'osm-tiles-source',
      'minzoom': 0,
      'maxzoom': 18,
      'layout': {
        'visibility': 'none'
      }
    },
    {
      'id': 'osmtopo-tiles-layer',
      'type': 'raster',
      'source': 'osmtopo-tiles-source',
      'minzoom': 0,
      'maxzoom': 18,
      'layout': {
        'visibility': 'none'
      }
    },
  ]

  if (isLoggedIn.value) {
    await store.dispatch('geo/fetchLayersToken')
    await store.dispatch('geo/fetchLayers')
  }

  // set planet tiles
  layers.value?.globals.forEach(layer => {
    baseSources[`planet-${layer.name}-source`] = {
      'type': 'raster',
      'tiles': [`${host}/tulum/tiles/1/${layer.name}/{z}/{x}/{y}.png?token=${layersToken.value}`],
      'tileSize': 256,
      'attribution': '&copy; <a href="https://www.planet.com/">Planet</a>'
    }

    baseLayers.push({
      'id': `planet-${layer.name}-layer`,
      'type': 'raster',
      'source': `planet-${layer.name}-source`,
      'minzoom': 0,
      'maxzoom': 18,
      'layout': {
        'visibility': 'none'
      }
    })
  })

  for (let source in baseSources) {
    map.value.addSource(source, baseSources[source])
  }

  for (let layer of baseLayers) {
    map.value.addLayer(layer)
  }
}

onMounted(async () => {
  await createMap()

  map.value.on('load', async () => {
    await store.dispatch('geo/mapReady', true)
    await addRasterLayers()
  })
})
</script>

<template>
  <div class="relative min-h-full w-full h-full bg-slate-100 overflow-hidden">
    <div id="mapDiv" class="map w-full h-full"></div>
    <div v-if="map && mapReady">
      <MapProjectLayer
        v-if="projectsToRender && projectsToRender.length"
        v-for="project in projectsToRender"
        :map="map"
        :key="project.project_id"
        :project="project">
      </MapProjectLayer>
      <MapDraw :map="map">
        <div class="absolute z-50 bottom-5 right-10 py-2 px-3" :class="{'mt-9': !!projectId}">
          <div class="flex items-end justify-end">
            <DialogCreate />
          </div>
        </div>
      </MapDraw>
    </div>
  </div>
</template>

<style>
.slideRight-enter-active, .slideRight-leave-active {
  transition: transform 0.1s ease-in-out, opacity 0.1s ease-in-out;
}
.slideRight-enter-from, .slideRight-leave-to {
  transform: translateX(-3%);
  opacity: 0;
}
</style>
