<script setup>
import { onMounted } from 'vue'
import { useStore } from 'vuex'

import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import MapboxDraw from '@mapbox/mapbox-gl-draw'

const store = useStore()
const props = defineProps({
  map: {
    type: Object,
    required: true
  }
})

onMounted(() => {
   const draw = new MapboxDraw({
    displayControlsDefault: false,
    styles: [
      {
        'id': 'highlight-active-points',
        'type': 'circle',
        'filter': ['all',
          ['==', '$type', 'Point'],
          ['==', 'meta', 'feature'],
          ['==', 'active', 'true']
        ],
        'paint': {
          'circle-radius': 12,
          'circle-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
          'circle-stroke-width': 3,
          'circle-stroke-color': '#fff',
        }
      },
      {
        'id': 'highlight-active-points-outlinebox',
        'type': 'circle',
        'filter': ['all',
          ['==', '$type', 'Point'],
          ['==', 'meta', 'feature'],
          ['==', 'active', 'true']
        ],
        'paint': {
          'circle-radius': 18,
          'circle-color': ['case', ['has', 'color'], ['get', 'color'], 'rgba(0, 0, 0, 0)'],
          'circle-stroke-width': 2,
          'circle-stroke-color': '#000000',
        }
      },
      {
        'id': 'points-are-blue',
        'type': 'circle',
        'filter': ['all',
          ['==', '$type', 'Point'],
          ['==', 'meta', 'feature'],
          ['==', 'active', 'false']
        ],
        'paint': {
          'circle-radius': 10,
          'circle-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
          'circle-stroke-width': 3,
          'circle-stroke-color': '#fff'
        }
      },
      // ACTIVE (being drawn)
      // line stroke
      {
        'id': 'gl-draw-line',
        'type': 'line',
        'filter': ['all',
          ['==', '$type', 'LineString'],
          ['==', 'active', 'true']
        ],
        'layout': {
          'line-cap': 'round',
          'line-join': 'round'
        },
        'paint': {
          'line-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
          'line-dasharray': [0.2, 2],
          'line-width': 4
        }
      },
      // polygon fill
      {
        'id': 'gl-draw-polygon-fill',
        'type': 'fill',
        'filter': ['all',
          ['==', '$type', 'Polygon'],
          ['==', 'active', 'true']
        ],
        'paint': {
          'fill-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
          'fill-outline-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
          'fill-opacity': 0.1
        }
      },
      // polygon outline stroke
      {
        'id': 'gl-draw-polygon-stroke-active',
        'type': 'line',
        'filter': ['all',
          ['==', '$type', 'Polygon'],
          ['==', 'active', 'true']
        ],
        'layout': {
          'line-cap': 'round',
          'line-join': 'round'
        },
        'paint': {
          'line-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
          'line-dasharray': [0.2, 2],
          'line-width': 4
        }
      },
      // vertex points
      {
        'id': 'gl-draw-points-active',
        'type': 'circle',
        'filter': ['all',
          ['==', 'meta', 'vertex'],
          ['==', '$type', 'Point'],
          ['==', 'active', 'true']
        ],
        'paint': {
          'circle-radius': 8,
          'circle-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
          'circle-stroke-width': 3,
          'circle-stroke-color': '#fff'
        }
      },
      // INACTIVE (static, already drawn)
      // line stroke
      {
        'id': 'gl-draw-line-static',
        'type': 'line',
        'filter': ['all',
          ['==', '$type', 'LineString'],
          ['==', 'active', 'false']
        ],
        'layout': {
          'line-cap': 'round',
          'line-join': 'round'
        },
        'paint': {
          'line-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
          'line-width': 2
        }
      },
      // polygon fill
      {
        'id': 'gl-draw-polygon-fill-static',
        'type': 'fill',
        'filter': ['all',
          ['==', '$type', 'Polygon'],
          ['==', 'active', 'false']
        ],
        'paint': {
          'fill-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
          'fill-outline-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
          'fill-opacity': 0.1
        }
      },
      // polygon outline
      {
        'id': 'gl-draw-polygon-stroke-static',
        'type': 'line',
        'filter': ['all',
          ['==', '$type', 'Polygon'],
          ['==', 'active', 'false']
        ],
        'layout': {
          'line-cap': 'round',
          'line-join': 'round'
        },
        'paint': {
          'line-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
          'line-width': 2
        }
      },
      // vertex points
      {
        'id': 'gl-draw-points-static',
        'type': 'circle',
        'filter': ['all',
          ['==', 'meta', 'vertex'],
          ['==', '$type', 'Point'],
          ['==', 'active', 'false']
        ],
        'paint': {
          'circle-radius': 7,
          'circle-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
          'circle-stroke-width': 2,
          'circle-stroke-color': '#fff'
        }
      }
    ]
  })

  props.map.addControl(draw, 'bottom-right')
  store.commit('geo/SET_DRAW', draw)
})
</script>

<template>
  <slot></slot>
</template>
